<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title>Edit Dispatch
				<span class="numFont title">
					{{ form.dispatchNo }}
				</span>
			</b-card-title>
			<b-card-sub-title>Handles the updates of existing dispatches for all companies </b-card-sub-title>
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

			<b-form class="mt-4" @submit.stop.prevent="handleSubmit" novalidate>
				<div role="tablist">
					<!-- SOURCE AND DESTINATION -->
					<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion1 variant="primary"
								class="text-sm-left accordion-title">
								SOURCE AND DESTINATION
							</b-btn>
						</b-card-header>
						<b-collapse v-model="showFirstPane" id="accordion1" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<b-row class="my-2">
									<b-col sm="12">
										<i class="icon-home"></i>&nbsp;
										<b>SOURCE</b>
									</b-col>
								</b-row>
								<b-row class="my-12">
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Company" label-for="Source Company">
											<v-select name="Source Company" class="style-chooser" label="text" placeholder=" - Please select - "
												:options="sourceCompanyOptions" :reduce="(company) => company.value"
												v-model="selSourceCompany" v-validate="'selectRequired'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Source Company')" class="help-block">
												{{ errors.first('Source Company') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Storage Location" label-for="Source Storage Location">
											<v-select name="Source Storage Location" class="style-chooser" label="text" placeholder=" - Please select - "
												:options="sourceLocationOptions" :reduce="(loc) => loc.value"
												v-model="selSourceLocation" v-validate="'selectRequired'">

												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a location
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Source Storage Location')" class="help-block">
												{{ errors.first('Source Storage Location') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="my-2">
									<b-col sm="12">
										<i class="icon-home"></i>&nbsp;
										<b>DESTINATION</b>
									</b-col>
								</b-row>
								<b-row class="my-12">
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Company" label-for="Destination Company">
											<v-select name="Destination Company" class="style-chooser" label="text" placeholder=" - Please select - "
												:options="destinationCompanyOptions"
												:reduce="(company) => company.value" v-model="selDestinationCompany"
												v-validate="'selectRequired'">

												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Destination Company')" class="help-block">
												{{ errors.first('Destination Company') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Storage Location" label-for="Destination Storage Location">
											<v-select name="Destination Storage Location" class="style-chooser"
												label="text" placeholder=" - Please select - " :options="destinationLocationOptions"
												:reduce="(loc) => loc.value" v-model="selDestinationLocation"
												v-validate="'selectRequired'">

												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a location
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Destination Storage Location')"
												class="help-block">
												{{ errors.first('Destination Storage Location') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>

								<!-- CLIENT ACCOUNT -->
								<b-row class="my-4">
									<b-col lg="4" md="6" sm="12" v-if="activeAccountOptions.length > 0">
										<i class="fa fa-address-book"></i>&nbsp;
										<b class="mb-2">CLIENT ACCOUNT</b>

										<b-form-group class="mt-2" label="Account No"
											description="For billing on dispatch w/ associated active accounts">
											<v-select name="Account No" class="style-chooser" label="text" placeholder=" - Please select - "
												:options="activeAccountOptions" :reduce="(accountNo) => accountNo.value"
												v-model="selAccountNo"
												v-validate="{ 'selectRequired': clientAccountIsRequired }">

												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for account no
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Account No')" class="help-block">
												{{ errors.first('Account No') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12">
										<i class="icon-info"></i>&nbsp;
										<b class="mb-2">REMARKS</b>

										<b-form-group class="mt-2" label="Remarks" label-for="Remarks" description>
											<b-form-textarea name="Remarks" type="text" v-model="form.notes"
												maxlength="200" v-validate="getValidationParam(true, remarksRegex)"
												:rows="3" placeholder="Remarks" />
											<span v-show="errors.has('Remarks')" class="help-block">
												{{ errors.first('Remarks') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>
							</b-card-body>
						</b-collapse>
					</b-card>

					<!-- TRANSPORTATION -->
					<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion3 variant="primary"
								class="text-sm-left accordion-title">
								TRANSPORTATION
							</b-btn>
						</b-card-header>
						<b-collapse id="accordion3" accordion="my-accordion" role="tabpanel">
							<b-card-body>

								<!-- Vehicle Selection -->
								<b-row class="mt-4 mb-2">
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Plate No./Conduction No." label-for="Plate No">
											<v-select class="style-chooser" name="Plate No" label="text" placeholder=" - Please select - "
												:options="allTransportationsOptions"
												:reduce="(selTransportation) => selTransportation.value"
												v-validate="'selectRequired'" v-model="selTransportation" />
											<span v-show="errors.has('Plate No')" class="help-block">
												{{ errors.first('Plate No') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" v-if="selTransportation.name !== ' - Others - '">
										<b-form-group label="Company" label-for="company">
											<b-form-input name="Company" type="text" placeholder="Company" v-model="selTransportation.company"
												maxlength="50" readonly />
										</b-form-group>
									</b-col>
								</b-row>
								<b-row v-if="selTransportation.name === ' - Others - '">
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Company" label-for="company">
											<b-form-input id="company" name="Company" type="text" placeholder="Company"
												v-model.trim="form.newTransportationToAdd.company"
												v-validate="getValidationParam(true, nameRegex)" maxlength="100" />
											<span v-show="errors.has('Company')" class="help-block">
												{{ errors.first('Company') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Plate No./Conduction No." label-for="plateNumber">
											<b-form-input id="plateNumber" name="Plate Number" type="text" placeholder="e.g. AAA-1111/A1-A111"
												v-model.trim="form.newTransportationToAdd.plateNo"
												v-validate="getValidationParam(true, plateNoRegex)" maxlength="12" />
											<span v-show="errors.has('Plate Number')" class="help-block">
												{{ errors.first('Plate Number') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>

								<!-- Driver Selection -->
								<b-row class="mt-4 mb-2" v-if="hasTransportPermission">
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Driver" label-for="Driver">
											<v-select class="style-chooser" name="Driver" label="text" placeholder=" - Please select - "
												:options="allDriverOptions" :reduce="(selDriver) => selDriver.value"
												v-validate="'selectRequired'" v-model="selDriver" />
											<span v-show="errors.has('Driver')" class="help-block">
												{{ errors.first('Driver') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col class="mt-1" lg="4" md="6" sm="12" v-if="selDriver && selDriver.id != null">
										<b-form-group description="NOTE: Min Value = 1, Max Value = 24;">
											<b-form-checkbox id="hasCheckingInterval" name="hasCheckingInterval"
												v-model="form.transportation.hasCheckingInterval">
												Checking Interval (hrs)
											</b-form-checkbox>
											<b-form-input id="checkingInterval" name="Checking Interval" type="number"
												v-model="form.transportation.checkingInterval"
												v-validate="`${form.transportation.hasCheckingInterval ? 'required|min_value:1|max_value:24' : ''}`"
												min="1" max="24" step="1" onwheel="this.blur()"
												placeholder="Checking Interval"
												:readonly="!form.transportation.hasCheckingInterval"
												class="mt-1 numFont" />
											<span v-show="errors.has('Checking Interval')" class="help-block">
												{{ errors.first('Checking Interval') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Driver Name">
											<b-form-input name="Driver Name" type="text" placeholder="Driver Name" v-model="form.driver.name"
												maxlength="50" v-validate="getValidationParam(true, fullNameRegex)"
												:readonly="(selDriver && selDriver.name !== ' - Others - ') && hasTransportPermission" />
											<span v-show="errors.has('Driver Name')" class="help-block">
												{{ errors.first('Driver Name') }}
											</span>
										</b-form-group>

										<!-- Driver's License -->
										<b-form-group
											v-if="(selDriver && selDriver.name === ' - Others - ') || !hasTransportPermission"
											label="Driver's License" label-for="drivers-license"
											description="*32-bit PNG, 1000px by 1000px, up to 1 MB">
											<b-form-file id="drivers-license" placeholder="Choose image"
												ref="dirvers-lincense-file-input" accept="image/png"
												@change="onSelectDriversLicense($event)">

												<template slot="file-name" slot-scope="{ names }">
													<b-badge variant="dark">{{ names[0] }}</b-badge>
													<b-badge v-if="names.length > 1" variant="dark" class="ml-1">
														+ {{ names.length - 1 }} More files
													</b-badge>
												</template>
											</b-form-file>
										</b-form-group>

										<!-- Image Preview -->
										<div v-if="driversLicense.url">
											<b-card overlay :img-src="driversLicense.url" img-alt="Card Image"
												text-variant="white" class="mb-0">
												<b-card-text class="text-right">
													<b-button v-if="selDriver && selDriver.name === ' - Others - '" variant="danger"
														size="sm" @click="onRemoveDriversLicense()">
														<em class="fa fa-trash"></em>
													</b-button>
												</b-card-text>
											</b-card>
											<b-progress v-if="uploading.uploadStatus === 'uploading'"
												:value="uploading.percentage" :max="uploading.filename"
												variant="success" height="8px" animated />
										</div>
									</b-col>
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Assistant/s" label-for="assistants">
											<b-form-input name="Assistants" type="text" placeholder="Assistant/s" v-model="form.driver.assistants"
												maxlength="50" v-validate="{ regex: fullNameRegex }" />
											<span v-show="errors.has('Assistants')" class="help-block">
												{{ errors.first('Assistants') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>

							</b-card-body>
						</b-collapse>
					</b-card>

					<!-- ASSETS -->
					<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion5 variant="primary"
								class="text-sm-left accordion-title">
								ASSETS
							</b-btn>
						</b-card-header>
						<b-collapse id="accordion5" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<div>
									<!-- ADD ASSETS -->
									<b-row no-gutters>
										<b-col sm="4" class="mr-4">
											<b-form-group label="Asset Type" label-for="assetType"
												description="The asset type you want to include to in this dispatch">
												<v-select name="assetType" class="style-chooser" label="text" placeholder=" - Please select - "
													:options="allAssetTypesOptions"
													:reduce="(assetType) => assetType.value" v-model="selAssetType">

													<template v-slot:no-options="{ search, searching }">
														<template v-if="searching">
															No results found for
															<em>
																<strong>{{ search }}</strong>
															</em>
														</template>
														<em :style="{ opacity: 0.5 }" v-else>
															Start typing to search for a user
														</em>
													</template>
												</v-select>
											</b-form-group>
										</b-col>
										<b-col lg="3" md="4" sm="4">
											<b-button variant="primary" class="add-button" @click="addAsset">
												Add Asset
											</b-button>
										</b-col>
									</b-row>

									<!-- SELECTED ASSETS -->
									<div>
										<b-row class="mt-4 mb-2">
											<b-col sm="8" class="md-left-text-sm-right">
												<strong>SELECTED ASSETS</strong>
											</b-col>
										</b-row>

										<b-row class="mb-2" v-show="form.assets.length > 0" no-gutters>
											<b-col sm="1" class="text-sm-center mr-1">
												<strong>#</strong>
											</b-col>
											<b-col sm="3" class="text-sm-left mr-1">
												<strong>Asset Type</strong>
											</b-col>
											<b-col sm="2" class="text-sm-center mr-1">
												<strong>Quantity</strong>
											</b-col>
											<b-col sm="1" class="text-sm-center mr-1">
												<strong>Action</strong>
											</b-col>
										</b-row>
										<b-row class="mb-2" v-for="(asset, key) in form.assets" :key="key" no-gutters>
											<b-col sm="1" class="text-sm-center mr-1">
												<strong>{{ key + 1 }}</strong>
											</b-col>
											<b-col sm="3" class="text-sm-left mr-1">
												{{ asset.assetType }}
											</b-col>
											<b-col sm="2" class="text-sm-left mr-1">
												<b-form-group :description="getAvailableStock(asset)">
													<b-form-input name="quantity" type="number" min="1" max="1000000"
														class="numFont text-right"
														v-model="selAssetTypes[asset.assetTypeId]" placeholder="0"
														v-validate="{ required: true, min_value: 1, max_value: 1000000 }" />
													<span v-show="errors.has('quantity')" class="help-block">
														{{ errors.first('quantity') }}
													</span>
												</b-form-group>
											</b-col>
											<b-col sm="1" class="text-sm-center mr-1">
												<b-button size="sm" v-b-tooltip.hover.top="'Remove Asset'"
													variant="danger" @click.stop="removeAsset(asset.assetTypeId)"
													class="mr-1">
													<em class="fa fa-trash"></em>
												</b-button>
											</b-col>
										</b-row>

										<b-row class="mb-2" v-show="form.assets.length === 0">
											<b-col sm="6" class="text-sm-left">
												<em>There is no selected assets yet.</em>
											</b-col>
										</b-row>
									</div>
								</div>
							</b-card-body>
						</b-collapse>
					</b-card>
				</div>
			</b-form>

			<!-- Navigation Buttons -->
			<b-row>
				<b-col md="12" sm="12" class="my-1 text-sm-right">
					<b-button variant="secondary" @click="returnToDispatchMain" class="mr-2">
						Back
					</b-button>
					<b-button v-b-modal.print-dispatch-summary v-b-tooltip.hover.top="'Preview Dispatch Summary'"
						variant="success" @click="printDispatch" :disabled="isLoading" class="mr-2">
						Preview
					</b-button>
					<b-button variant="primary" @click="handleOk" :disabled="isLoading">
						Save
					</b-button>
				</b-col>
				<b-col md="1" sm="1" class="my-1"></b-col>
			</b-row>
		</b-card>

		<!-- Modals -->
		<PrintDispatchSummary :allStorageLocationsObj="allStorageLocationsObj" :allUsersObj="allUsersObj" />
	</div>
</template>

<script>
// Utils
import { AssetPoolDistributionUtil } from '@/utils/assetPoolDistributionUtil';
import { ClientAccountUtil } from '@/utils/clientAccountUtil';
import { DateUtil } from '@/utils/dateutil';
import { DispatchUtil } from '@/utils/dispatchUtil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { FileUtil } from '@/utils/fileUtil';
import { ValidationUtil } from '@/utils/validationUtil';

//Components
import PrintDispatchSummary from '@/views/transactions/dispatch/PrintDispatchSummary';

// API
import dispatchApi from '@/api/dispatchApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { storage } from '@/config/firebase';
import _ from 'lodash';

export default {
	name: 'edit-dispatch',
	components: {
		Loading,
		PrintDispatchSummary,
	},
	data() {
		return {
			form: { ...DispatchUtil.getDefaultDispatchObj() },
			showFirstPane: true,
			driversLicense: {},
			uploading: {
				filename: '',
				percentage: 0,
				uploadStatus: '', // [uploading, success, error]
			},

			sourceCompanyOptions: [],
			destinationCompanyOptions: [],
			sourceLocationOptions: [],
			destinationLocationOptions: [],
			activeAccountOptions: [],

			selSourceCompany: { ...config.companyDefaultValue },
			selDestinationCompany: { ...config.companyDefaultValue },
			selSourceLocation: { ...config.storageLocationDefaultValue },
			selDestinationLocation: { ...config.storageLocationDefaultValue },
			selAccountNo: { ...config.clientAccountDefaultValue },
			selTransportation: { ...config.transportationDefaultValue },
			selAssetType: { ...config.assetTypeDefaultValue },
			selDriver: { ...config.driverDefaultValue },
			selAssetTypes: [],

			// Dispatch Params
			params: {},

			allTransportationsOptions: [],
			allDriverOptions: [],
			allAssetTypesOptions: [],

			allCompaniesObj: {},
			allStorageLocationsObj: {},
			allConnectionsObj: {},
			allUsersObj: {},
			allTransportationsObj: {},
			allAssetTypesObj: {},
			allClientAccountsObj: {},
			allCompanyAssetPoolsObj: {},
			allDriversObj: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			hasTransportPermission: this.$store.getters.hasTransportPermission,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,

			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		allCompaniesObj: function () {
			this.sourceCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
		},

		selSourceCompany: function () {
			this.onChangeSourceCompany();
		},

		selDestinationCompany: function () {
			this.onChangeDestinationCompany();
		},

		selTransportation: function (newVal) {
			if (!newVal || _.isEmpty(newVal)) {
				this.selTransportation = { ...config.transportationDefaultValue };
			} else {
				this.form.newTransportationToAdd = {};
				if (newVal.name === ' - Others - ') {
					this.form.newTransportationToAdd = {
						company: '', // required
						companyId: '',
						hasCheckingInterval: false,
						checkingInterval: 0,
						createdBy: this.loggedUser.emailAddress,
						dateCreated: DateUtil.getCurrentTimestamp(),
						dateUpdated: null,
						description: null,
						id: null,
						isActive: 'true',
						plateNo: '', // required
						updatedBy: null,
					};

					this.form.transportation.hasCheckingInterval = false;
					this.form.transportation.checkingInterval = 0;
				} else {
					// Set predefined checking interval values
					this.form.transportation.hasCheckingInterval = newVal.hasCheckingInterval ? newVal.hasCheckingInterval : false;
					this.form.transportation.checkingInterval = newVal.checkingInterval ? parseInt(newVal.checkingInterval) : 0;
				}
			}
		},

		selDriver: function (newVal, oldVal) {
			if (newVal && (newVal.id || newVal.name === ' - Others - ')) {
				this.driversLicense = newVal.license;
				if (oldVal.name !== ' - Please select - ' && newVal.name === ' - Others - ') {
					this.form.driver.name = '';
				}
				this.updateDriverSelection();
			} else {
				this.form.driver = { ...config.dispatchDriverDefaultValue }
				this.driversLicense = {};
			}
		},

		'form.transportation.checkingInterval': function () {
			this.form.transportation.checkingInterval = parseInt(this.form.transportation.checkingInterval);
		}
	},
	computed: {
		remarksRegex() {
			return config.remarksRegex;
		},
		fullNameRegex() {
			return config.fullNameRegex;
		},
		nameRegex() {
			return config.nameRegex;
		},
		plateNoRegex() {
			return config.plateNoRegex
		},
		driverName() {
			return this.selDriver && this.selDriver.name !== ' - Please select - ' ? this.selDriver.name : '';
		},
		clientAccountIsRequired() {
			return this.activeAccountOptions && this.activeAccountOptions.length > 0;
		}
	},
	mounted() {
		setTimeout(async () => {
			// Filter Access
			if (this.$store.getters.isViewer) {
				this.$router.push('/dashboard');
				this.$toaster.warning('You are not allowed to access this page.');
			}

			// show loading indicator
			this.isLoading = true;

			await this.getCurrentLocation();

			// init parameters
			this.params = this.$store.getters.dispatchParams;

			// Objects
			this.allCompaniesObj = this.params.allCompaniesObj;
			this.allStorageLocationsObj = this.params.allStorageLocationsObj;
			this.allConnectionsObj = this.params.allConnectionsObj;
			this.allUsersObj = this.params.allUsersObj;
			this.allTransportationsObj = this.params.allTransportationsObj;
			this.allAssetTypesObj = this.params.allAssetTypesObj;
			this.allClientAccountsObj = this.params.allClientAccountsObj;
			this.allCompanyAssetPoolsObj = this.params.allCompanyAssetPoolsObj;
			this.allDriversObj = { ...this.$store.getters.driverUsers };

			// reset options
			this.sourceCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
			this.allTransportationsOptions = DropDownItemsUtil.retrieveTransportations(this.allTransportationsObj);
			this.allDriverOptions = DropDownItemsUtil.retrieveActiveDrivers(this.allDriversObj);
			this.allAssetTypesOptions = DropDownItemsUtil.retrieveAssetTypes(this.allAssetTypesObj, true);

			this.onReset();

			// hide loading indicator
			this.isLoading = false;
		}, config.timeout);
	},
	methods: {
		getAvailableStock(asset){
			let remaining = asset && asset.totalRemaining ? asset.totalRemaining.toLocaleString() : '0';
			return 'Available Stock: ' + remaining;
		},
		getValidationParam(isRequired, regex) {
			return {
				required: isRequired,
				regex: regex,
			};
		},
		async getCurrentLocation() {
			try {
				this.currLocation = await this.$getCurrentLocation();
			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			}
		},
		resetSourceStorageLocationOptions() {
			this.sourceLocationOptions = [];
			this.sourceLocationOptions.push({
				value: { ...config.storageLocationDefaultValue },
				text: ' - Please select - ',
			});

			this.selSourceLocation = { ...config.storageLocationDefaultValue };
		},
		resetDestinationCompanyOptions() {
			this.destinationCompanyOptions = [];
			this.destinationCompanyOptions.push({
				value: { ...config.companyDefaultValue },
				text: ' - Please select - ',
			});

			this.selDestinationCompany = { ...config.companyDefaultValue };
		},
		resetDestinationStorageLocationOptions() {
			this.destinationLocationOptions = [];
			this.destinationLocationOptions.push({
				value: { ...config.storageLocationDefaultValue },
				text: ' - Please select - ',
			});

			this.selDestinationLocation = { ...config.storageLocationDefaultValue };
		},
		printDispatch() {
			this.form = this.processDispatchForm();
			this.$store.commit('SET_CURR_DISPATCH', this.form);
			EventBus.$emit('onPrintDispatch', this.form);
		},

		onChangeSourceCompany() {
			if (!this.selSourceCompany || this.selSourceCompany.id === null) {
				// reset all source and destination fields
				this.resetSourceStorageLocationOptions();
				this.resetDestinationCompanyOptions();
				this.resetDestinationStorageLocationOptions();
			} else {
				// retrieve the source storage locations
				this.sourceLocationOptions =
					DropDownItemsUtil.retrieveStorageLocationsByConnection(
						this.allConnectionsObj,
						this.allStorageLocationsObj,
						this.selSourceCompany.id,
						this.selSourceCompany.id
					);

				// pre-select the single option value
				if (this.sourceLocationOptions.length === 2) {
					this.selSourceLocation = this.sourceLocationOptions[1].value;
				}

				// update destination company options
				if (this.selSourceCompany && this.selSourceCompany.id !== null) {
					if (this.isSuperAdmin) {
						this.destinationCompanyOptions = DropDownItemsUtil.retrieveConnectedCompanies(this.allConnectionsObj, this.selSourceCompany);
					} else {
						let companyId = this.selSourceCompany.id;
						let parentCompanyId = this.allCompaniesObj[this.selSourceCompany.id].parentCompanyId;

						if (companyId === this.loggedUserCompany.id || parentCompanyId === this.loggedUserCompany.id) {
							this.destinationCompanyOptions = DropDownItemsUtil.retrieveConnectedCompanies(this.allConnectionsObj, this.selSourceCompany);
						} else {
							let companiesObj = _.filter(this.allCompaniesObj, o => {
								return o.id === this.loggedUserCompany.id || o.parentCompanyId === this.loggedUserCompany.id;
							});
							this.destinationCompanyOptions = DropDownItemsUtil.retrieveCompanies(companiesObj);
						}
					}
				}

				this.form.fromInactiveNode = DispatchUtil.isInactiveCompany(this.allCompaniesObj[this.selSourceCompany.id]);

				this.updateAssetTypeOptions();
				this.updateClientAccountOptions();
			}
		},
		onChangeDestinationCompany() {
			if (!this.selDestinationCompany || this.selDestinationCompany.id === null) {
				// retrieve the destination storage locations
				this.resetDestinationStorageLocationOptions();
			} else {
				if (this.selSourceCompany.isActive !== 'true') {
					this.destinationLocationOptions =
						DropDownItemsUtil.retrieveStorageLocationsByCompany(
							this.selDestinationCompany.id,
							this.allStorageLocationsObj
						);
				} else {
					this.destinationLocationOptions =
						DropDownItemsUtil.retrieveStorageLocationsByConnection(
							this.allConnectionsObj,
							this.allStorageLocationsObj,
							this.selSourceCompany.id,
							this.selDestinationCompany.id
						);
				}

				// pre-select the single option value
				if (this.destinationLocationOptions.length == 2) {
					this.selDestinationLocation = this.destinationLocationOptions[1].value;
				}

				this.form.toInactiveNode = DispatchUtil.isInactiveCompany(this.allCompaniesObj[this.selDestinationCompany.id]);

				this.updateAssetTypeOptions();
				this.updateClientAccountOptions();
			}
		},
		updateAssetTypeOptions() {
			if (this.isSuperAdmin) {
				if (this.form.fromInactiveNode === 'true') {
					let destinationCompanyId = this.selDestinationCompany.id;
					this.allAssetTypesOptions = DropDownItemsUtil.retrieveAssetTypesByAccountability(this.allAssetTypesObj, this.allCompanyAssetPoolsObj, destinationCompanyId, true);
				} else {
					let sourceCompanyId = this.selSourceCompany.id;
					this.allAssetTypesOptions = DropDownItemsUtil.retrieveAssetTypesByAccountability(this.allAssetTypesObj, this.allCompanyAssetPoolsObj, sourceCompanyId, true);
				}
			} else {
				let sourceCompanyId = this.selSourceCompany.id;
				this.allAssetTypesOptions = DropDownItemsUtil.retrieveAssetTypesByAccountability(this.allAssetTypesObj, this.allCompanyAssetPoolsObj, sourceCompanyId, true);
			}
		},
		updateClientAccountOptions() {
			let sourceCompanyId = this.selSourceCompany && this.selSourceCompany.id ? this.selSourceCompany.id : "";
			let destinationCompanyId = this.selDestinationCompany && this.selDestinationCompany.id ? this.selDestinationCompany.id : "";

			if (sourceCompanyId.length > 0 && destinationCompanyId.length > 0) {
				let filteredAccountsObj = ClientAccountUtil.getActiveClientAccounts(this.allClientAccountsObj, sourceCompanyId, destinationCompanyId);
				if (!_.isEmpty(filteredAccountsObj)) {
					this.activeAccountOptions = DropDownItemsUtil.retrieveClientAccounts(filteredAccountsObj, true);
				} else {
					this.activeAccountOptions = [];
				}
			} else {
				this.activeAccountOptions = [];
			}
		},

		plateNoExists(transportation) {
			let currPlateNo = '';

			let currDispatch = this.$store.getters.currDispatch;
			if (
				!_.isEmpty(currDispatch) &&
				currDispatch.transportation !== null &&
				currDispatch.transportation.plateNo !== null
			) {
				currPlateNo = currDispatch.transportation.plateNo;
			}

			const plateNos = _.map(this.allTransportationsObj, (o) => {
				let plateNo = ""
				if (o.plateNo && !_.isEmpty(o.plateNo)) {
					plateNo = o.plateNo.toUpperCase();
				}
				return plateNo;
			});

			return (
				plateNos.includes(transportation.plateNo.toUpperCase()) &&
				currPlateNo != transportation.plateNo
			);
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();
			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input');
				this.isLoading = false;
				return;
			}

			// source and destination must not be the same
			if (this.selSourceCompany.id === this.selDestinationCompany.id && this.selSourceLocation.id === this.selDestinationLocation.id) {
				this.$toaster.warning('Source and Destination should not be the same');
				this.isLoading = false;
				return;
			}

			// for new transportation created on-the-fly
			if (this.selTransportation.name === ' - Others - ') {
				if (!DispatchUtil.isValidTransportation(this.form.newTransportationToAdd)) {
					this.$toaster.warning('Company or Plate Number is required.');
					this.isLoading = false;
					return;
				} else if (this.plateNoExists(this.form.transportation)) {
					this.$toaster.warning('Plate number already exists.');
					this.isLoading = false;
					return;
				}
			}

			if (DispatchUtil.exceedMaximumAssetTypes(this.form)) {
				this.$toaster.warning('You have exceeded the allowed number of asset types (30) per dispatch.');
				this.isLoading = false;
				return;
			}

			this.form = this.processDispatchForm();
			await this.handleSubmit();
		},

		processDispatchForm() {
			// Update Dispatch Fields
			this.form.dateUpdated = DateUtil.getCurrentTimestamp();
			this.form.updatedBy = this.loggedUser.id;

			// Removes excess whitespace
			this.form.notes = ValidationUtil.removeExcessWhiteSpace(this.form.notes);

			this.updateDriverSelection();
			this.updateSourceSelection();
			this.updateDestinationSelection();
			this.updateClientAccountSelection();
			this.updateTransportationSelection();
			this.updateAssetsSelection();

			return this.form;
		},
		updateDriverSelection() {
			if (this.selDriver && this.selDriver.id) {
				this.form.driver.userId = this.selDriver.id;
				this.form.driver.company = this.selDriver.company;
				this.form.driver.companyId = this.selDriver.companyId;
				this.form.driver.name = this.selDriver.name;
				this.form.driver.license = this.selDriver.license;
			} else {
				this.form.driver.userId = '';
				this.form.driver.company = '';
				this.form.driver.companyId = '';
				this.form.driver.name = ValidationUtil.removeExcessWhiteSpace(this.form.driver.name);

				// Update License Details
				this.driversLicense.name = `DL_${this.form.dispatchId}_${this.form.driver.name}.jpg`;
				this.form.driver.license.url = this.driversLicense.url ? this.driversLicense.url : '';
				this.form.driver.license.name = this.driversLicense.name ? this.driversLicense.name : '';
				this.form.driver.license.longitude = this.currLocation ? this.currLocation.lng : 0;
				this.form.driver.license.latitude = this.currLocation ? this.currLocation.lat : 0;
			}

			this.form.driver.assistants = ValidationUtil.removeExcessWhiteSpace(this.form.driver.assistants);
		},
		updateSourceSelection() {
			this.form.source = {
				company: this.selSourceCompany.name !== ' - Please select - ' ? this.selSourceCompany.name : '-',
				companyId: this.selSourceCompany.id,
				storageLocation: this.selSourceLocation.name !== ' - Please select - ' ? this.selSourceLocation.name : '',
				storageLocationId: this.selSourceLocation.id,
				geoaddress: this.selSourceLocation.geoaddress,
			};
		},
		updateDestinationSelection() {
			this.form.destination = {
				company: this.selDestinationCompany.name !== ' - Please select - ' ? this.selDestinationCompany.name : '-',
				companyId: this.selDestinationCompany.id,
				storageLocation: this.selDestinationLocation.name !== ' - Please select - ' ? this.selDestinationLocation.name : '-',
				storageLocationId: this.selDestinationLocation.id,
				geoaddress: this.selDestinationLocation.geoaddress,
			};
		},
		updateClientAccountSelection() {
			if (_.isEmpty(this.selAccountNo) || !this.selAccountNo.id) {
				this.form.clientAccountId = null;
				this.form.clientAccountNo = null;
			} else {
				this.form.clientAccountId = this.selAccountNo.id;
				this.form.clientAccountNo = this.selAccountNo.accountNo;
			}
		},
		updateTransportationSelection() {
			if (!_.isEmpty(this.form.newTransportationToAdd)) {
				this.form.transportation.plateNo = this.form.newTransportationToAdd.plateNo;
				this.form.transportation.company = this.form.newTransportationToAdd.company;
				this.form.transportation.companyId = this.form.newTransportationToAdd.companyId;
			} else {
				this.form.transportation.plateNo = this.selTransportation.name;
				this.form.transportation.company = this.selTransportation.company;
				this.form.transportation.companyId = this.selTransportation.companyId;
			}
		},
		updateAssetsSelection() {
			for (const asset of this.form.assets) {
				asset.expectedQuantity = this.selAssetTypes[asset.assetTypeId] ? parseInt(this.selAssetTypes[asset.assetTypeId]) : 0;
			}
		},

		async uploadDriversLicensePhoto(dispatch) {
			let image = this.driversLicense;
			if (image._isNew) {
				const uploadResult = await this.firebaseUploadImage(image);
				if (uploadResult.name && uploadResult.url) {
					dispatch.driver.license.url = uploadResult.url;
					const updateResult = await dispatchApi.saveDispatch(
						dispatch,
						this.loggedUser.id
					);
					if (updateResult.isSuccess) {
						this.onUploadingInProgress('success');
					}
				}
			}
		},
		async firebaseUploadImage(mediaFile) {

			this.onUploadingInProgress('uploading', mediaFile.name, 0);

			return new Promise((resolve, reject) => {
				let storageRef = storage.ref(`${mediaFile.fbStoragePath}/${mediaFile.name}`);
				let task = storageRef.put(mediaFile.file);

				task.on(
					'state_changed',
					(snapshot) => {
						let percentage =
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						this.onUploadingInProgress('uploading', mediaFile.name, percentage);
					},
					(error) => {
						reject(error);
						this.onResetUploadingState();
					},
					() => {
						task.snapshot.ref.getDownloadURL().then((downloadURL) => {
							resolve({
								name: mediaFile.name,
								url: downloadURL,
							});
						});
					}
				);
			});
		},
		onUploadingInProgress(uploadStatus, filename = '', percentage = 0) {
			this.uploading.filename = filename;
			this.uploading.percentage = percentage;
			this.uploading.uploadStatus = uploadStatus;
		},
		onResetUploadingState() {
			this.uploading = {
				filename: '',
				percentage: 0,
				uploadStatus: '',
			};
		},
		onRemoveDriversLicense() {
			this.driversLicense = {};
			this.$refs['dirvers-lincense-file-input'].reset();
		},
		onSelectDriversLicense(evt) {
			const vm = this;
			const file = evt.target.files[0];

			if (!FileUtil.isValidImgFileType(file)) {
				this.$toaster.error('Invalid File Type: Please import a valid license photo in PNG or JPEG format.');
				this.driversLicense = {};
				return;
			}

			const url = URL.createObjectURL(file);
			let dimensions = { w: 0, h: 0 };

			const image = new Image();
			image.onload = function () {
				dimensions.w = image.width;
				dimensions.h = image.height;

				if (dimensions.w > 1000 || dimensions.h > 1000) {
					vm.$toaster.warning("Drivers lincense photo's width and height shouldn't be greater than 1000 pixels");
				} else {
					vm.driversLicense = {
						url: url,
						file: file,
						fbStoragePath: 'images/driversLicenses',
						_isNew: true,
					};
				}
			};
			image.src = url;
		},

		async handleSubmit() {
			let dispatchNo = this.form.dispatchNo;

			try {
				let { data } = await dispatchApi.saveDispatch(this.form, this.loggedUser.id);

				if (!data.isSuccess) {
					this.$toaster.warning(data.message);
					// hide loading indicator
					this.isLoading = false
					
				} else {
					// upload driverse license photo
					if (!_.isEmpty(this.driversLicense)) {
						await this.uploadDriversLicensePhoto(data.dispatch);
					}

					this.$toaster.success(`Dispatch "${dispatchNo}" was updated successfully.`);
					EventBus.$emit('onCloseSaveDispatch', data.dispatch);
					this.addNewTransportationToStore(data.dispatch);

					// reset the state and revert to dispatch page
					this.returnToDispatchMain();
				}

			} catch (error) {
				this.$toaster.error(`Error updating dispatch ${dispatchNo}. Please try again.`);
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
		addNewTransportationToStore(dispatch) {
			if (dispatch.newTransportationToAdd && !_.isEmpty(dispatch.newTransportationToAdd)) {
				let newTransportations = {};
				newTransportations[dispatch.newTransportationToAdd.id] =
					dispatch.newTransportationToAdd;
				this.$store.dispatch('updateAllTransportations', newTransportations);
			}

			if (dispatch.newCompanyToAdd && !_.isEmpty(dispatch.newCompanyToAdd)) {
				let newCompanies = {};
				newCompanies[dispatch.newCompanyToAdd.id] = dispatch.newCompanyToAdd;
				this.$store.dispatch('updateAllCompanies', newCompanies);
			}
		},
		returnToDispatchMain() {
			this.params.fromEditDispatch = true;

			this.$store.dispatch('setDispatchParams', this.params);
			this.$store.dispatch('setCurrentDispatch', {});

			if (!this.isSuperAdmin) {
				this.$router.push({ path: '/dispatch' });
			} else {
				this.$router.push({ path: '/admin/admin-dispatch' });
			}
		},

		async addAsset() {
			if (this.selAssetType.id === null) {
				this.$toaster.warning('Please select an asset type to add');
				return;
			} else if (DispatchUtil.isExistingAssetType(this.form.assets, this.selAssetType.id)) {
				let assetTypeName = this.selAssetType.name;
				this.$toaster.warning(`Asset type "${assetTypeName}" already added.`);
				return;
			} else if (DispatchUtil.exceedMaximumAssetTypes(this.form, true)) {
				this.$toaster.warning('You have exceeded the allowed number of asset types (30) per dispatch.');
				return;
			} else {
				this.form.assets.push(DispatchUtil.getDefaultDispatchAssetObj(this.selAssetType));
				await this.retrieveTotalAvailableAssets(this.selSourceLocation, this.form.assets);
			}
		},
		removeAsset(assetTypeId) {
			for (let i = 0; i < this.form.assets.length; i++) {
				let asset = this.form.assets[i];
				if (asset.assetTypeId === assetTypeId) {
					this.form.assets.splice(i, 1);
					delete this.selAssetTypes[assetTypeId];
				}
			}
		},
		async retrieveTotalAvailableAssets(locObj, assets) {
			try {
				// show loading indicator
				this.isLoading = true;

				await AssetPoolDistributionUtil.retrieveTotalAvailableAssets(locObj, assets);

			} catch (error) {
				console.error('Error retrieving asset pool:', error);
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},

		async onReset() {
			// show loading indicator
			this.isLoading = true;

			/* Reset our form values */
			let dispatch = this.$store.getters.currDispatch;
			dispatch = DispatchUtil.cleanupFields(dispatch);

			// primary details
			this.form.id = dispatch.id;
			this.form.dispatchId = dispatch.dispatchId;
			this.form.dispatchNo = dispatch.dispatchNo;
			this.form.source = dispatch.source;
			this.form.destination = dispatch.destination;
			this.form.status = dispatch.status;
			this.form.notes = dispatch.notes;

			// transportation
			this.form.transportation = dispatch.transportation;
			this.form.driver = dispatch.driver;
			this.form.newTransportationToAdd = dispatch.newTransportationToAdd;
			this.driversLicense = DispatchUtil.getDriverLicenseObj(this.form.driver);

			this.onResetDropDownValues(dispatch);

			// assets
			this.form.assets = dispatch.assets;
			await this.retrieveTotalAvailableAssets(this.selSourceLocation, this.form.assets);
			for (const asset of this.form.assets) {
				this.selAssetTypes[asset.assetTypeId] = asset.expectedQuantity;
			}

			// timestamps
			this.form.dateCreated = dispatch.dateCreated;
			this.form.createdBy = dispatch.createdBy;
			this.form.dateUpdated = dispatch.dateUpdated;
			this.form.updatedBy = dispatch.updatedBy;

			// others
			this.form.creationSource = dispatch.creationSource;
			this.form.fromInactiveNode = dispatch.fromInactiveNode;
			this.form.toInactiveNode = dispatch.toInactiveNode;

			if (dispatch.validationWarnings) {
				this.form.validationWarnings = dispatch.validationWarnings;
			}

			this.onResetUploadingState();

			// reset validation
			this.$validator.reset();
			this.errors.clear();

			// hide loading indicator
			this.isLoading = false;
		},
		onResetDropDownValues(dispatch) {
			// SOURCE COMPANY
			let source = dispatch.source;
			let sourceCompany = this.allCompaniesObj[source.companyId];
			this.selSourceCompany = DropDownItemsUtil.getCompanyItem(sourceCompany);

			// SOURCE LOCATION
			let sourceLoc = this.allStorageLocationsObj[source.storageLocationId];
			this.selSourceLocation = DropDownItemsUtil.getStorageLocationItem(sourceLoc);

			// DESTINATION COMPANY
			let destination = dispatch.destination;
			let destinationCompany = this.allCompaniesObj[destination.companyId];
			this.selDestinationCompany = DropDownItemsUtil.getCompanyItem(destinationCompany);

			// DESTINATION LOCATION
			let destiLoc = this.allStorageLocationsObj[destination.storageLocationId];
			this.selDestinationLocation = DropDownItemsUtil.getStorageLocationItem(destiLoc);

			// ACCOUNT NO
			if (dispatch.clientAccountId) {
				let clientAccountObj = this.allClientAccountsObj[dispatch.clientAccountId];
				this.selAccountNo = DropDownItemsUtil.getClientAccountItem(clientAccountObj);
			} else {
				this.selAccountNo = { ...config.clientAccountDefaultValue };
			}

			// DRIVER
			if (dispatch.driver.userId) {
				this.selDriver = DropDownItemsUtil.getDriverItem(dispatch.driver);
			} else {
				this.selDriver = { ...config.driverOtherValue };
			}

			// TRANSPORTATION
			this.selTransportation = DropDownItemsUtil.getTransportationItem(dispatch.transportation);
		},
	},
};
</script>

<style scoped>
.title {
	font-size: 22px !important;
}

.accordion-title {
	color: white !important;
}
</style>
